:root {
   --base-primary: #358DCE;
   --base-secondary: #FED428;
}

.text-center {
   text-align: center;
}

.text-green {
   color: green;
}

body {
   margin: 0 auto;
   padding: 0;
   background-color: white;
   font-family: 'Montserrat', sans-serif;
   overflow-x: hidden;
}

.header1 {
   color: var(--base-primary);
   font-family: 'Montserrat', sans-serif;
   font-style: normal;
   font-weight: 500;
   font-size: 60px;
   line-height: 64px;

}

header {
   position: fixed !important;
   width: 100%;
   top: 0 !important;
   padding-left: 40px;
   padding-right: 40px;
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.19);
   z-index: 11;
   background: white;
}

.header2 {
   font-family: 'Montserrat', sans-serif;
   font-style: normal;
   font-weight: normal;
   font-size: 24px;
   line-height: 28px;
   color: #000000;
}

.navbar {
   padding-bottom: 0 !important;
   padding-top: 0 !important;
}

.row {
   position: relative;
}

.hero-phone {
   width: 135%;
   max-width: unset;
}

#root {
   overflow-x: hidden;
}

.con-fluid {
   margin-top: 80px;
}

.pre-reg-btn {
   font-family: 'Montserrat', sans-serif;
   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   width: 250px;
   line-height: 21px;
   text-align: center;
   letter-spacing: -0.015em;
   color: rgba(0, 0, 0, 0.8);
}

.take-suv {
   color: var(--base-primary);
   border: 1px solid var(--base-primary)
}

.take-suv:hover {
   background: var(--base-primary);
   color: white;
}

.why-need {
   font-style: normal;
   font-weight: bold;
   font-size: 37px;
   line-height: 61px;
   color: #FFFFFF;
}

.why-need-client,
.why-need-worker {
   font-style: normal;
   font-weight: bold;
   color: #FFFFFF;
   font-size: 66px;
   /* //font-size: 4.125rem; */
   line-height: 74px;
}

.bg-base-lightblue {
   background-color: #F1F9FF;
}

.bg-base-lightyellow {
   background-color: #FFF6D0;
}

.card-base-header {
   font-family: 'Montserrat', sans-serif;
   font-style: normal;
   font-weight: 600;
   font-size: 22px;
   line-height: 29px;
   color: #358CCF;

}

.card-base-header2 {
   font-family: 'Montserrat', sans-serif;
   font-style: normal;
   font-weight: 600;
   font-size: 22px;
   line-height: 31px;
   color: #C28D00;

}

.be-first {
   /* font-family: Roboto, sans-serif; */
   font-style: normal;
   font-weight: bold;
   font-size: 40px;
   line-height: 61px;
   /* text-align: right; */

   color: #358CCF;

   background-color: #FAFAFA;
   ;

}

.how-it-work {
   /* font-family: Roboto, sans-serif; */
   font-style: normal;
   font-weight: bold;
   font-size: 62px;
   line-height: 118px;
   text-align: center;
   color: #358CCF;

}

.list-item-point {
   /* font-family: Roboto, sans-serif; */
   font-style: normal;
   font-weight: normal;
   font-size: 30px;
   line-height: 61px;
   color: #212121;
   padding-left: 20px;
}

.d-list {
   padding: 6rem 3rem;
}

.worker-header {
   /* // font-family: Roboto, sans-serif; */
   font-style: normal;
   font-weight: bold;
   font-size: 46px;
   line-height: 61px;
   color: #C28D00;
}

.client-header {
   /* // font-family: Roboto, sans-serif; */
   font-style: normal;
   font-weight: bold;
   font-size: 46px;
   line-height: 61px;
   color: var(--base-primary);
}


.card-base-body {
   /* // font-family: Roboto, sans-serif; */
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 23px;
   color: #595959;
}

.text-base-deep-yellow {
   color: #C28D00;
}

.coming-soon {
   font-style: normal;
   font-weight: normal;
   font-size: 30px;
   line-height: 50px;
   letter-spacing: -0.015em;
   color: rgba(0, 0, 0, 0.8);
}

.other-product-header {
   font-style: normal;
   font-weight: bold;
   font-size: 40px;
   line-height: 61px;
   text-align: center;
   color: #358CCF;
   margin-top: 100px;
}

.small-media-center {
   text-align: center;
}

.client-phone {
   width: 370px;
   margin-top: -165px;
}

.client-phone-worker {
   position: absolute;
   width: 400px;
   height: 300px;
   right: 145px;
   margin-top: -90px;
}



label {
   font-family: 'Montserrat', sans-serif;
   font-style: normal;
   font-weight: bold;
   font-size: 14px;
   line-height: 20px;
   /* //display: flex;
   //align-items: center;
   //text-align: center; */
   letter-spacing: -0.015em;

   color: rgba(0, 0, 0, 0.8);
}

footer {
   background: linear-gradient(180deg, rgba(172, 172, 172, 0.05) 0%, rgba(196, 196, 196, 0) 100%);
   margin-top: 100px;
}

.copy-right {
   font-family: 'Montserrat', sans-serif;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 30px;
   /* //text-align: center; */
   letter-spacing: -0.015em;
   color: #358CCF;
}

a {
   text-decoration: none;
   cursor: pointer;
}

.input-container-width {
   width: 38%;
}

.client-container {
   height: 210px;
   /* // width: 100%; */
}

.img-mark {
   height: 31px;
   width: 31px
}

.img-other1 {
   height: 52px;
   width: 298px
}

.social-icon {
   height: 30px;
   width: 30px;
}

.social-icon2 {
   height: 32px;
   width: 32px;
}


.survey-section {
   width: 250px;
   /* // background: #eee; */
   box-shadow: 0px 3px 7px #eee;
   text-align: left;
   border-radius: 5px;
   list-style: none;
   padding: 0;
   position: absolute;
   margin-top: 57px;
   left: 0;
   margin-left: 40px;
}

.survey-section li a {
   text-decoration: none;
   padding: 10px;
   display: block;
   cursor: pointer;
   color: inherit;
}

.survey-section li a:hover {
   background: var(--base-primary);
   color: #fff !important;
}


@media(max-width:1200px) {
   .hero-phone {
      width: 160% !important;
   }

   .header1 {
      font-size: 55px;
   }
}

@media(max-width:1060px) {

   .header1 {
      font-size: 50px;
   }
}


@media(max-width:991px) {
   .hero-phone {
      width: 130% !important;
   }

   .header1 {
      font-size: 60px;
      line-height: unset;
      text-align: center;
   }

   .header2 {
      text-align: center;
   }

   .hero-button {
      justify-content: center;
   }

   .small-media-20 {
      order: -1;
      margin-top: 35px !important;
   }

   .con-fluid {
      margin-top: 40;
      padding: 0 20px;
   }

   .client-phone {
      width: 300px;
      margin-top: -96px;
   }

   .bg-base-lightyellow,
   .bg-base-lightblue {
      text-align: center;
   }

}

@media (max-width:767px) {

   .why-need-client {
      font-size: 40px;
      position: absolute;
      background-color: var(--base-primary);
      height: 80px !important;
      bottom: 0;
   }

   .why-need-worker {
      font-size: 40px;
      position: absolute;
      background-color: var(--base-secondary);
      height: 80px !important;
      bottom: 0;
   }

   /* .small-media-center { */
   /* display: flex; */
   /* align-items: center; */
   /* justify-content: center; */
   /* } */

   .header1 {
      font-size: 45px;
   }

   .client-phone {
      margin-top: -160px;
   }
}


@media (max-width:567px) {

   .bg-base-lightyellow .px-5,
   .bg-base-lightblue .px-5 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
   }

   .header1 {
      font-size: 30px;
      font-weight: 800 !important;
   }

   .hero-button {
      display: block !important;
      text-align: center;
   }

   .take-suv {
      margin-left: 0 !important;
      margin-top: 10px;
   }

   .client-phone {
      width: 250px;
      margin-top: -115px;
   }

   .be-first {
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 30px;
   }

   .how-it-work {
      font-size: 30px;
      padding-bottom: 10px;
   }

   header {
      padding-left: 10px;
      padding-right: 10px;
   }
}

@media (max-width:400px) {
   .d-list {
      padding: 6rem 1rem;
   }

}


@media only screen and (max-width: 600px) and (min-width: 200px) {
   .input-container-width {
      width: 100%;
   }

   .client-phone-worker {
      position: relative;
      width: 100%;
      height: auto;
      right: auto;
      margin-top: -90px;

   }

   .small-media-pb-20 {
      padding-bottom: 20px;
   }

   .worker-header {
      font-size: 30px;
      line-height: 44px;
   }

   .client-header {
      font-size: 30px;
      line-height: 44px;
   }

   .img-mark {
      height: 15px;
      width: 15px
   }

   .list-item-point {
      font-size: 15px;
      line-height: 40px;
   }

   .why-need {
      font-size: 30px;
      line-height: 40px;
   }

   .other-product-header {
      font-size: 30px;
      line-height: 40px;
      padding-bottom: 10px;
   }
}